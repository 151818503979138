const axios = require('axios')

export class ColorPicker {
  options = {
    endpoint: ''
  }

  constructor (options) {
    this.options = {
      ...this.options,
      ...options
    }
  }

  getColorSets (callback) {
    axios.get(this.options.endpoint).then((response) => {
      try {
        response = response.data
        if (response.status !== 'success') {
          throw response.error.length > 0 ? response.error[0] : 'Unknown Error'
        }
        if (typeof callback === 'function') callback(response.data)
      } catch (e) {
        console.error(e)
      }
    })
  }
}
