<template>
  <div class="page page-tools-random">
    <div class="container heading">
      <div class="row">
        <div class="col">
          <h1>Color Picker</h1>
          <hr />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9 order-2 order-sm-1 colorSetContainer">
          <template v-for="colorSet in colorSets" :key="colorSet.id">
            <h5 class="colorSetTitle">{{ colorSet.title }}</h5>
            <div class="colorSets">
              <div
                class="colorSet"
                v-for="cSet in colorSet.colorSet"
                :key="cSet"
                :class="{active:JSON.stringify(featureSet) === JSON.stringify(cSet)}"
              >
                <router-link :to="{query: {colorSet: JSON.stringify(cSet)}}">
                  <template v-for="cTile in cSet" :key="cTile">
                    <div
                      class="colorTile"
                      :style="{background: '#'+cTile}"
                    ></div>
                  </template>
                </router-link>
              </div>
            </div>
          </template>
        </div>
        <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 order-1 order-sm-2" v-if="featureSet.length > 0">
          <h5>Selected Color Set</h5>
          <div class="featureSet colorSets">
            <template v-for="cTile in featureSet" :key="cTile">
              <div class="colorSet">
                <div
                  class="colorTile"
                  :style="{background: '#'+cTile}"
                ></div>
                <div><input :value="'#'+cTile" onclick="this.select()" readonly /></div>
              </div>
              <div class="colorRGB">
                <div> R <input :value="getRGB(cTile).r" onclick="this.select()" readonly /></div>
                <div> G <input :value="getRGB(cTile).g" onclick="this.select()" readonly /></div>
                <div> B <input :value="getRGB(cTile).b" onclick="this.select()" readonly /></div>
              </div>
              <div class="colorCMYK" v-if="false">
                <div> C <input :value="getCMYK(cTile).c" onclick="this.select()" readonly /></div>
                <div> M <input :value="getCMYK(cTile).m" onclick="this.select()" readonly /></div>
                <div> Y <input :value="getCMYK(cTile).y" onclick="this.select()" readonly /></div>
                <div> K <input :value="getCMYK(cTile).k" onclick="this.select()" readonly /></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col">
          <i
            >Reference:
            <a href="https://www.books.com.tw/products/0010899875" target="_blank"
              >零基礎配色學 - 1456 組好感色範例，秒速解決你的配色困擾！</a
            ></i
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ColorPicker } from '@/module/ColorPicker.js'

export default {
  name: 'ColorPicker',
  data: function () {
    return {
      featureSet: [],
      colorSets: []
    }
  },
  watch: {
    '$route.query.colorSet': function (value) {
      this.showColor(value)
    }
  },
  methods: {
    showColor (colorSet) {
      try {
        this.featureSet = JSON.parse(colorSet)
      } catch (e) {
        this.featureSet = []
      }
      if (this.featureSet.length > 0 && window.innerWidth <= 576) {
        document.getElementsByTagName('html')[0].scrollTop = 0 // scroll to top when mobile
      }
    },
    selectColorCode (elem) {
      console.log(elem)
    },
    hex2dec (hex) {
      return parseInt(hex, 16)
    },
    getRGB (colorCode) {
      return {
        r: this.hex2dec(colorCode.substring(0, 2)),
        g: this.hex2dec(colorCode.substring(2, 4)),
        b: this.hex2dec(colorCode.substring(4, 6))
      }
    },
    getCMYK (colorCode, normalized) {
      var r = this.getRGB(colorCode).r
      var g = this.getRGB(colorCode).g
      var b = this.getRGB(colorCode).b

      var c = 1 - (r / 255)
      var m = 1 - (g / 255)
      var y = 1 - (b / 255)
      var k = Math.min(c, Math.min(m, y))

      c = (c - k) / (1 - k)
      m = (m - k) / (1 - k)
      y = (y - k) / (1 - k)

      if (!normalized) {
        c = Math.round(c * 10000) / 100
        m = Math.round(m * 10000) / 100
        y = Math.round(y * 10000) / 100
        k = Math.round(k * 10000) / 100
      }

      c = isNaN(c) ? 0 : c
      m = isNaN(m) ? 0 : m
      y = isNaN(y) ? 0 : y
      k = isNaN(k) ? 0 : k

      return {
        c: c,
        m: m,
        y: y,
        k: k
      }
    }
  },
  mounted () {
    if (this.$route.query.colorSet !== undefined &&
        this.$route.query.colorSet.length > 0) {
      this.showColor(this.$route.query.colorSet)
    }
    new ColorPicker({
      endpoint: this.$store.state.endpoint + 'list_colorset'
    }).getColorSets((colorSets) => {
      this.colorSets = colorSets
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/toolshead.scss';

h1 {
  margin-bottom: 10px;
  text-align: left;
  color: $key-yellow;
  font-size: 2.8em;
  font-weight: 700;
}
.colorSetTitle {
  font-weight: 500;
}
.colorSets {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  background: #ffffff;
  color: $key-grey;
  padding: 1em;
  user-select: none;
  &.featureSet {
    grid-auto-flow: row;
    grid-template-columns: initial;
    .colorSet {
      justify-content: flex-start;
      .colorTile {
        margin-right: 10px;
      }
    }
    .colorRGB, .colorCMYK {
      display: grid;
      grid-auto-flow: column;
      font-size: 0.8em;
      input {
        width: 3em;
        font-size: 1em;
      }
    }
    input {
      text-align: center;
      width: 5em;
      padding: 0;
      margin: 0;
      font-size: 0.8em;
    }
  }

  .colorSet {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    white-space: nowrap;

    &.active {
      border: 2px dashed $key-grey;
    }
    a {
      display: flex;
      justify-content: center;;
    }
    .colorTile {
      display: inline-block;
      width: 1em;
      height: 1em;
    }
  }
}
.colorSetContainer {
  height: calc(100vh - 200px);
  overflow: auto;
}
@media (max-width: 992px) {
  .colorSets {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 576px) {
  .colorSets {
    grid-template-columns: repeat(3, 1fr);
  }
  .colorSetContainer {
    height: initial;
    overflow: initial;
  }
}
</style>
